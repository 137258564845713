import { responsiveFontSizes } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

/** 容器最大宽度 */
export const ContainerMaxWidth = 1200

// colors
const primary = "#0654D6";
const primaryLight = "rgba(6, 84, 214, .1)";
// rgb(243, 160, 0)
const secondary = "#F3A000";
const secondaryLight = "rgba(243, 160, 0, .1)";

const roseRed = "#F4573A";
const green = "#06E115";
const black = "#343a40";
const black33 = "#333333";
const black66 = "#666666";
const darkBlack = "rgb(36, 40, 44)";
// 112, 112, 112
const gray = "#707070";
// 191, 191, 191(#BFBFBF)
const grayLight = "rgba(191, 191, 191, 0.2)";

const background = "#F9FCFF";
const lightBlue = "#DFECFD";
const transparent = "transparent";
// warning colors #FCCE00
const warn = "rgba(252, 206, 0)";
const warningLight = "rgba(252, 206, 0, .3)";
const warningMain = "rgba(252, 206, 0, .5)";
const warningDark = "rgba(252, 206, 0, .7)";

// border
const borderWidth = 1;
const borderColor = "rgba(191, 191, 191, 0.1)";

// breakpoints
const xl = 1536;
const lg = 1200;
const md = 900;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

// button border Radius
const borderRadiusCard = 10;
const borderRadiusBtn = 30;

// tab shadows
const shadows = `0px -4px 4px 1px rgb(255 255 255 / 33%), 0px 0px 2px 0px rgb(6 84 214 / 4%), 0px 2px 10px 1px rgb(6 84 214 / 10%)`;

const secondaryGradient = `linear-gradient(${warn}, ${secondary})`;

const theme = createTheme({
  palette: {
    primary: { main: primary, mainLight: primaryLight },
    secondary: { main: secondary, mainLight: secondaryLight },
    common: {
      black,
      black33,
      black66,
      darkBlack,
      gray,
      grayLight,
      roseRed,
      green,
      transparent,
      lightBlue,
    },
    warning: {
      warn,
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      secondary: secondaryGradient,
      transparent,
    },
    spacing,
    shadows,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        color: black,
        boxShadow: shadows,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.8rem",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#FFFFFF"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "9px 14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: shadows,
      }
    },
  },
  shape: {
    borderRadiusBtn,
    borderRadiusCard,
  },
  typography: {
    useNextVariants: true,
    body3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.75rem",
      fontWeight: 400,
      letterSpacing: "0.01204em",
      lineHeight: 1.36,
    },
  },
});

export default responsiveFontSizes(theme);
