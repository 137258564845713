import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0,
    },
    ".pointer": {
      cursor: "pointer"
    },
    ".scroll-x-auto": {
      overflowX: "auto",
    },
    /**
     * text
     */
    ".text-primary": {
      color: theme.palette.primary.main,
    },
    ".text-secondary": {
      color: theme.palette.secondary.main,
    },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".text-red": {
      color: theme.palette.common.roseRed,
    },
    ".text-gray": {
      color: theme.palette.common.gray,
    },
    ".text-black": {
      color: theme.palette.common.black,
    },
    ".text-wrap": {
      whiteSpace: "nowrap",
    },
    ".text-dot": {
      position: "relative",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing(1),
      },
      "&:after": {
        content: "close-quote",
        position: "absolute",
        left: 0,
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.main,
        top: theme.spacing(1),
        width: theme.spacing(1),
        height: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          top: theme.spacing(0.9),
          width: theme.spacing(0.6),
          height: theme.spacing(0.6),
        },
      },
    },
    ".text-bold": {
      fontWeight: theme.typography.fontWeightBold,
    },
    ".text-medium": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".text-body3": theme.typography.body3,
    ".title-text-main": {
      paddingBottom: theme.spacing(1),
      fontWeight: `${theme.typography.fontWeightBold} !important`,
      fontSize: theme.typography.h6.fontSize,
      lineHeight: theme.typography.h6.lineHeight,
      [theme.breakpoints.down("sm")]: theme.typography.body1,
      [theme.breakpoints.down("xs")]: theme.typography.body2,
    },
    ".body-text-main": {
      fontWeight: `${theme.typography.fontWeightMedium} !important`,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      [theme.breakpoints.down("sm")]: theme.typography.body2,
      [theme.breakpoints.down("xs")]: theme.typography.body3,
    },
    ".container": {
      width: "100%",
      maxWidth: 1200,
      marginRight: "auto",
      marginLeft: "auto",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    ".container-fluid": {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      maxWidth: 1200,
      minWidth: 248,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
      },
    },
    ".container-fluid-main": {
      width: "100%",
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
      [theme.breakpoints.up("md")]: {
        width: `${theme.breakpoints.values.md - theme.spacing(10)}px`,
        marginRight: "auto",
        marginLeft: "auto",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(9)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(8)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: `${theme.spacing(6)}px !important`,
      },
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(9)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(8)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(6)}px !important`,
      },
    },
    ".md-mg-bottom": {
      marginBottom: `${theme.spacing(9)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(8)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(6)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(4)}px !important`,
      },
    },
    ".xs-mg-bottom": {
      marginBottom: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(1.8)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(1.5)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(1.2)}px !important`,
      },
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(9)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(8)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(6)}px !important`,
      },
    },
    /**
     * flex
     */
     ".col-start": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    ".col-start-around": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "flex-start",
    },
    ".col-center": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    ".row-center": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".row-between-center": {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "center"
    },
    ".row-start-center": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    ".row-end-center": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    ".row-between": {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    ".row-ai-center": {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
    },
    ".row-start": {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    ".row-end": {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    ".row-flex-0": {
      display: "flex",
      flexDirection:"row",
      flex: 0,
    },
    ".row-flex-1": {
      display: "flex",
      flexDirection:"row",
      flex: 1,
    },
    ".col-flex-1": {
      display: "flex",
      flexDirection:"column",
      flex: 1,
    },
    ".cell-label": {
      display: "flex",
      flex: 1,
      flexDirection:"row",
      justifyContent:"flex-start",
      alignItems:"center",
      paddingRight:theme.spacing(1)
    },
    ".cell-value": {
      display: "flex",
      flex: 1,
      flexDirection:"row",
      justifyContent:"flex-end",
      alignItems:"center",
      paddingLeft:theme.spacing(1),
    },
    /** 图片宽度100%缩放 */
    ".fit-img": {
      width: "100%",
      objectFit: "contain",
      transform: "translateZ(0)",
    },
    /** 基本布局 */
    ".layout-base": {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("md")]: {
        maxWidth: 1000,
      },
    },
    /**
     * backgroundColor
     */
    ".white-page": {
      backgroundColor: theme.palette.background.paper,
    },
    ".default-page": {
      backgroundColor: theme.palette.background.default,
    },
    ".primary-page": {
      backgroundColor: theme.palette.primary.main,
    },
    ".secondary-page": {
      backgroundColor: theme.palette.secondary.main,
    },
    ".red-page": {
      backgroundColor: theme.palette.common.roseRed,
    },
    ".green-page": {
      backgroundColor: theme.palette.common.green,
    },
    ".light-gray-page": {
      // #707070
      backgroundColor: "rgba(112, 112, 112, 0.1)"
    },
    /**
     * btn active
     */
    ".btn-active": {
      color: theme.palette.common.white,
      background: `${theme.palette.background.secondary} !important`,
    },
    /**
     * lineHeight
     */
    ".lh-n": {
      lineHeight: 0,
    },
    /**
     * padding
     */
    ".pt2": {
      paddingTop: theme.spacing(2),
    },
    /**
     * card padding
     */
    ".card-padding": {
      [theme.breakpoints.up("xs")]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(5),
      },
    },

    /**
     * padding T/B spacing1
     */
    ".mv-spacing-1": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    /**
     * padding T/B spacing2
     */
    ".mv-spacing-2": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    /**
     * form-ctrl
     */
    ".form-ctrl": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    /**
     * 分割线-虚线
     */
    ".dashed-line": {
      height: 0,
      borderTop: `dashed ${theme.border.borderWidth}px ${theme.palette.common.gray}`
    },
    /**
     * 百分百宽度
     */
     ".full-width": {
      width: '100%',
    },
    // icon btn 取消背景
    ".icon-hover-none": {
      "&:hover": {
        backgroundColor: theme.palette.common.transparent,
      },
    },
    // operation btn
    ".operation-btn": {
      [theme.breakpoints.down("sm")]: {
        borderRadius: theme.shape.borderRadiusBtn,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    // chip
    ".status-chip": {
      height: theme.spacing(2.5),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
