import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "shared/components/Pace";

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
// 用户协议
const UserPrivacy = lazy(() => import("./logged_out/agreement/User"));
// 隐私政策
const Privacy = lazy(() => import("./logged_out/agreement/Privacy"));

// const HmBaidu = props => {
//   (function () {
//     var hm = document.createElement("script");
//     hm.src = "https://hm.baidu.com/hm.js?da2b8817e594349977d2c5283be7bde4";
//     var s = document.getElementsByTagName("script")[0];
//     s.parentNode.insertBefore(hm, s);
//   })();
//   return props.children;
// };

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Switch>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/agreement">
              <UserPrivacy />
            </Route>
            <Route>
              <LoggedOutComponent />
            </Route>
            {/* <HmBaidu>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/agreement">
                <UserPrivacy />
              </Route>
              <Route>
                <LoggedOutComponent />
              </Route>
            </HmBaidu> */}
          </Switch>
          {/* <Route>
            <LoggedOutComponent />
          </Route> */}
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
